import "./bootstrap";
import "../css/app.css";

import { createApp, DefineComponent, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { ZiggyVue } from "../../vendor/tightenco/ziggy/dist/vue.m";
import * as Sentry from "@sentry/vue";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
// @ts-ignore
import InstantSearch from "vue-instantsearch/vue3/es";
import "instantsearch.css/themes/reset.css";

const appName = import.meta.env.VITE_APP_NAME || "Laravel";

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) =>
    resolvePageComponent(
      `./Pages/${name}.vue`,
      import.meta.glob<DefineComponent>("./Pages/**/*.vue")
    ),
  setup({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) });

    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
      environment: import.meta.env.VITE_APP_ENV,
      release: import.meta.env.VITE_APP_VERSION,
      trackComponents: true,
      tunnel: "/stats/performance",
      tracesSampleRate: 1.0,
      tracePropagationTargets: [
        "localhost",
        new RegExp(`^${import.meta.env.VITE_APP_URL}`),
      ],
      replaysOnErrorSampleRate: 1.0,
      replaysSessionSampleRate: 1.0,
      integrations: [
        new Sentry.BrowserTracing(),
        new Sentry.Replay({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
    });

    app.use(plugin).use(ZiggyVue, Ziggy).use(InstantSearch).mount(el);

    return app;
  },
  progress: {
    color: "#4B5563",
  },
});
